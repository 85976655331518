<template>
  <div>
    <!--    <PokergoplayOverlay />-->

    <component v-bind:is="layout" :class="tabCustom">
      <router-view @authenticated="setAuthenticated" />
    </component>

    <div class="bottom-bar">
      <ul>
        <li>
          <router-link to="/">
            <i class="fas fa-home"></i>
            <span>Home</span>
          </router-link>
        </li>
        <li>
          <router-link to="/on-demand">
            <i class="fas fa-play"></i>
            <span>On Demand</span>
          </router-link>
        </li>
        <li>
          <router-link to="/schedule">
            <i class="fas fa-calendar-alt"></i>
            <span>Schedule</span>
          </router-link>
        </li>
        <li>
          <a
            href="https://playpokergo.onelink.me/YuJc/nh56lr4w"
            target="_blank"
            class="text-center"
          >
            <svg
              class="icon "
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
              style="fill: #808080; height: 20px; margin-bottom: 5px"
            >
              <path
                d="M7.5 194.9c-15.4-26.6-6.3-60.7 20.4-76.1L220.7 7.5c26.6-15.4 60.7-6.3 76.1 20.4l167 289.3c15.4 26.6 6.2 60.7-20.4 76.1L250.5 504.5c-26.6 15.4-60.7 6.2-76.1-20.4L7.5 194.9zM197 159.6c-11.1-3-22.6 3.6-25.6 14.8l-7.2 26.9-20.4 76.1c-7 26 8.5 52.7 34.4 59.7s52.7-8.5 59.7-34.4l2.4-8.8c.1-.4 .2-.8 .3-1.1l17.7 30.7-12.1 7c-6.7 3.8-8.9 12.4-5.1 19s12.4 8.9 19 5.1l48.2-27.8c6.7-3.8 8.9-12.4 5.1-19s-12.4-8.9-19-5.1l-12.1 7-17.7-30.7c.4 .1 .8 .2 1.1 .3l8.8 2.4c26 7 52.7-8.5 59.7-34.4s-8.5-52.7-34.4-59.7l-76.1-20.4L197 159.6zM459.4 420.9c41.9-24.2 56.3-77.8 32.1-119.8L354.7 64.2c1.7-.2 3.5-.2 5.3-.2l224 0c30.9 0 56 25.1 56 56l0 336c0 30.9-25.1 56-56 56l-224 0c-13.7 0-26.2-4.9-35.9-13l135.3-78.1zm74.5-183.3L582 187.6c14-14.5 13.2-38.5-2.2-51.9c-14-11.7-34.5-9.5-46.9 3.2l-4.7 5.2-5-5.2c-12.5-12.7-33.2-15-46.7-3.2c-15.7 13.5-16.5 37.5-2.5 51.9l48.4 49.9c3 3.2 8.2 3.2 11.5 0z"
              />
            </svg>
            <span>Play</span>
          </a>
        </li>
        <li>
          <router-link to="/options">
            <i class="fas fa-bars"></i>
            <span>More</span>
          </router-link>
        </li>
      </ul>
    </div>
    <portal-target name="modal-outlet" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import DefaultLayout from './layouts/default.vue'
import EmbedLayout from './layouts/embed.vue'
import DefaultNoFooter from './layouts/defaultNoFooter.vue'
import Footer from '@/components/Footer'
import Header from '@/components/Header'
import LoginLayout from './layouts/login.vue'
import RegisterLayout from './layouts/register.vue'
import { PortalTarget } from 'portal-vue'

import { localstorage } from '@/services/storage/localStorageService'
import { General } from './library/general'
import PokergoplayOverlay from './components/PokergoplayOverlay'
require('./plugins')

export default {
  name: 'App',
  components: {
    PokergoplayOverlay,
    DefaultLayout,
    EmbedLayout,
    DefaultNoFooter,
    Footer,
    Header,
    LoginLayout,
    RegisterLayout,
    PortalTarget
  },
  data: () => ({
    timer: null,
    windowWidth: 900
  }),
  computed: {
    ...mapGetters(['isAuthenticated', 'isSubscriber']),
    register() {
      return this.$route.path === '/register'
    },
    layout() {
      return this.$route.meta.layout || 'default-layout'
    },
    tabCustom() {
      if (this.$route.path === '/training') {
        return 'bg-training'
      } else {
        return 'tab'
      }
    },
    isMobile() {
      return this.windowWidth <= 1024
    }
  },
  watch: {
    isMobile: {
      handler(newVal) {
        if (newVal) {
          this.removeSupportBtn()
        } else {
          const supportEl = document.querySelector('#freshworks-container')
          if (supportEl) supportEl.style.display = 'block'
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.$store.dispatch('fetchAndSetBanners')
    if (this.$store.state.user.email) {
      window.profitwell('start', { user_email: this.$store.state.user.email })
    } else {
      window.profitwell('start', {})
    }

    this.windowWidth = window.innerWidth

    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
      })
    })

    setTimeout(() => {
      console.log(
        "          _____\n         |2 .  | _____\n         | /.\\ ||3 ^  | _____\n         |(_._)|| / \\ ||8 _  | _____\n         |  |  || \\ / || ( ) ||4_ _ |\n         |____V||  .  ||(_'_)||( v )|\n                |____V||  |  || \\ / |\n                       |____V||  .  |\n                              |____V|\nYou just flopped. Stop peaking here and get back to watching Poker Videos!\nAny security concerns or reports send an email directly to support@pokergo.com\n"
      )
    }, 5000)
  },
  methods: {
    ...mapMutations(['setAuthenticated']),
    logout() {
      localstorage.clearAuth()
      this.$router.push({ name: 'HomeView' })
      window.location.reload()
    },
    removeSupportBtn() {
      this.timer = setInterval(() => {
        const supportEl = document.querySelector('#freshworks-container')
        if (supportEl) {
          supportEl.style.display = 'none'
          clearInterval(this.timer)
        }
      }, 100)
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/app.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}
.bottom-bar {
  visibility: hidden;
}

@media screen and (max-width: 1025px) {
  .bottom-bar {
    width: 100%;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    background: #141414;
    visibility: visible;
    border-top: 1px solid #5b5b5b;

    ul {
      height: 60px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 0;
      padding: 0px;
      li {
        list-style: none;

        a {
          display: flex;
          flex-direction: column;
          color: gray;
          i {
            text-align: center;
            margin-bottom: 5px;
            font-size: 20px;
          }
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>

<style src="video.js/dist/video-js.css"></style>
<style>
@import url('https://fonts.googleapis.com/css?family=Lato|Open+Sans&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
.tab {
  position: absolute;
  width: 100%;
  background: url('./assets/images/Background@2x.png') no-repeat;
  background-size: contain;
}
.bg-training {
  position: absolute;
  width: 100%;
  background: url('./assets/images/training/bg-hero.png') no-repeat;
  background-size: contain;
}
.bgNotAuthenticated {
  background: url('./assets/images/Background@2x.png') no-repeat;
  backdrop-filter: blur(5px);
  background-size: 100%;
}
.router-link-exact-active {
  color: #ffffff !important;
}
@media screen and (max-width: 768px) {
  .page {
    margin-top: 83px !important;
  }
}
</style>
